import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function scrollCarousel() {

    if (document.querySelector(".feature")) {
        gsap.defaults({ overwrite: 'auto' });

        // starting position
        // gsap.set(".left-content > *", { xPercent: -50, yPercent: -50 }); //manually set so it doesnt interfere with mobile sizing

        // scroll trigger init
        const ST = ScrollTrigger.create({
            trigger: ".right-content",
            start: "top top+=121.97px", //top + nav size
            end: "bottom bottom-=250px",
            toggleClass: "active",
            onUpdate: getCurrentSection,
            pin: ".left-content"
        });

        //trigger
        const contentMarkers = gsap.utils.toArray(".content-trigger");

        // content behaviors
        contentMarkers.forEach(marker => {
            marker.content = document.querySelector(`#${marker.dataset.markerContent}`);

            if (marker.content.tagName === "DIV") {
                gsap.set(marker.content, { transformOrigin: "right" });

                marker.content.enter = function () {
                    gsap.fromTo(marker.content, { autoAlpha: 0, rotateY: -25 }, { duration: 0.5, autoAlpha: 1, rotateY: 0 });
                }
            }

            marker.content.leave = function () {
                gsap.to(marker.content, { duration: 0.2, autoAlpha: 0 });
            }

        });

        // Handle the updated position
        let lastContent;
        function getCurrentSection() {
            let newContent;
            const currScroll = scrollY;

            // Find the current section
            contentMarkers.forEach(marker => {
                if (currScroll > marker.offsetTop) {
                    newContent = marker.content;
                }
            });

            // If the current section is different than that last, animate in
            if (newContent && (lastContent == null || !newContent.isSameNode(lastContent))) {
                // Fade out last section
                if (lastContent) {
                    lastContent.leave();
                }

                // Animate in new section
                newContent.enter();

                lastContent = newContent;
            }

        }

        const media = window.matchMedia("screen and (max-width: 992px)");
        ScrollTrigger.addEventListener("refreshInit", checkSTState);
        ScrollTrigger.refresh();
        checkSTState();

        function checkSTState() {
            if (media.matches) {
                ST.disable();
            } else {
                ST.enable();
            }
        }
    }

}