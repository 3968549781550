//Jquery
import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

//Bootstrap JS
import * as bootstrap from 'bootstrap';

//animate on scroll (aos)
import AOS from 'aos';
import 'aos/dist/aos.css';

//GSAP
import { scrollCarousel } from './gsap';

AOS.init();

//global
const body = document.body;

//mobile menu
const mobileToggle = document.getElementById("toggle-menu");
const mobileOverlay = document.getElementById("mobile-overlay");

mobileToggle.addEventListener("click", () => {
    mobileOverlay.style.display = mobileToggle.checked ? "block" : "none";
    body.classList.toggle("overflow-hidden", mobileToggle.checked);
});

mobileOverlay.addEventListener("click", removeOverlay);

window.addEventListener("resize", () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 768) {
        removeOverlay();
    }
});
function removeOverlay() {
    body.classList.remove("overflow-hidden");
    mobileOverlay.style.display = "none";
    mobileToggle.checked = false;
}

//contact us
let forms = document.querySelectorAll('.needs-validation')

Array.prototype.slice.call(forms).forEach(function (form) {
    form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
        }

        form.classList.add('was-validated')
    }, false)
})

//Update the select based on the data-enquiry attribute
function updateSelectFromLink(dataEnquiryValue) {
    if (selectElement) {
        selectElement.value = dataEnquiryValue;
    }
}

const selectElement = document.getElementById('floatingSelect');
const linkElements = document.querySelectorAll('[data-enquiry]');

linkElements.forEach(linkElement => {
    const dataEnquiryValue = linkElement.getAttribute('data-enquiry');
    
    linkElement.addEventListener('click', function(event) {
        event.preventDefault(); // Prevent the default link behavior
        updateSelectFromLink(dataEnquiryValue);

        // Construct the URL with the parameter
        const url = `./contact.html?enquiry=${encodeURIComponent(dataEnquiryValue)}#contact-form`;

        // Navigate to the new URL
        window.location.href = url;
    });

    const urlParams = new URLSearchParams(window.location.search);
    const enquiryValue = urlParams.get('enquiry');
    
    if (selectElement && enquiryValue === dataEnquiryValue) {
        updateSelectFromLink(enquiryValue);
    }
});

//gsap
scrollCarousel();